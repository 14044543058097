<template>
    <div>
        <div class="pill px-2 px-md-3" @click.stop.prevent="showPopup">
            <nuxt-resource class="coin mr-1" src="images/loyalty/popup/coin.svg" alt="Dundle coin icon" />
            <span class="mb-0 text">{{ translate('loyalty.coinsEarned', { value: coinsEarned }) }}</span>
        </div>

        <div v-if="popupActive" class="popup" @click.stop.prevent="popupActive = false">
            <div class="close-button d-none d-md-block" @click="popupActive = false">
                <UIIcon :name="['fal', 'times']" />
            </div>
            <div class="content d-flex flex-column align-items-center">
                <div class="loyalty">
                    <nuxt-resource
                        src="images/loyalty/popup/loyalty-popup-top.png"
                        class="chest"
                        alt="Loyalty coins chest graphic"
                    />
                    <div class="loyalty-content">
                        <h2 class="title">
                            {{ translate('loyalty.boxTitle') }}
                        </h2>
                        <p class="text">
                            {{ translate('loyalty.introText') }}
                        </p>
                    </div>
                </div>
                <div class="buttons-wrapper">
                    <UIButton class="mr-2 mb-3 button" @click.stop.prevent="popupActive = false">
                        {{ translate('loyalty.noTreasureButton') }}
                    </UIButton>
                    <UIButton class="ml-2 mb-3 button world" variant="loyalty-minimal" @click="navigateToLoyalty">
                        {{ translate('loyalty.returnPageButton') }}
                    </UIButton>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { UIButton, UIIcon } from '@dundle/ui/components';
import TranslationMixin from '~/mixins/TranslationMixin';

export default {
    name: 'LoyaltyCoinsPill',
    components: {
        UIButton,
        UIIcon,
    },
    mixins: [TranslationMixin],
    props: {
        coinsEarned: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            popupActive: false,
        };
    },
    methods: {
        showPopup() {
            this.$gtm?.push({ event: 'event_dundle_coins_popup' });
            this.popupActive = true;
        },
        navigateToLoyalty() {
            this.$gtm?.push({ event: 'event_dundle_coins_popup_navigated_to_loyalty' });
            this.$router.push(this.$locale.path('reward-info'));
        },
    },
};
</script>

<style lang="scss" scoped>
.pill {
    background-color: rgba(247, 181, 0, 0.18);
    border-radius: $search-border-radius;
    position: relative;

    &:hover {
        cursor: pointer;
    }

    .coin {
        width: 28px;
        position: absolute;
        left: 0;
        top: -1px;
    }

    .text {
        font-size: 0.85rem;
        color: #edab1c;

        margin-left: 1.8rem;
    }

    @media screen and (min-width: 768px) {
        .coin {
            left: -5px;
        }

        .text {
            margin-left: 1rem;
        }
    }
}

.popup {
    width: 100%;
    height: 100%;

    left: 0;
    top: 0;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background-color: rgba(0, 0, 0, 0.8);
    position: fixed;
    z-index: 1000;

    .content {
        margin-bottom: 6rem;
    }

    .loyalty {
        position: relative;
        max-width: 90%;

        background: url(https://cdn.dundle.com/resources/images/loyalty/intro-step-1-bottom.svg);
        background-size: 100%;
        background-position: bottom center;
        background-repeat: no-repeat;

        padding: 2rem 0;

        filter: drop-shadow(0 0 100px rgba(255, 242, 0, 0.3));

        .title,
        .text {
            max-width: 90%;
            text-align: center;
        }

        .title {
            margin-top: 1rem;
        }

        .text {
            color: $color-grey-9;
            margin-bottom: 1rem;
        }

        @media screen and (min-width: 768px) {
            margin-top: 5rem;

            max-width: 355px;
        }

        .loyalty-content {
            background: url(https://cdn.dundle.com/resources/images/loyalty/intro-step-1-middle.svg) repeat-y;
            background-size: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            position: relative;
            padding: 1rem;
        }

        .chest {
            min-width: 110%;
            margin-left: -1.1rem;
            margin-bottom: -0.2rem;
        }
    }

    .buttons-wrapper {
        position: absolute;
        bottom: 0;

        .button {
            min-width: 140px;

            &.world {
                min-width: 195px;
            }
        }

        @media screen and (min-width: 768px) {
            position: initial;

            margin-top: 2rem;
        }
    }

    .close-button {
        position: fixed;
        padding: 0.5rem;
        right: 1rem;
        top: 1rem;
        font-size: 1rem;
        color: $color-bg;
        border-radius: 50%;
        width: 3rem;
        height: 3rem;
        background-color: rgba($color-bg, 0.1);
        & > svg {
            position: relative;
            width: 2rem;
            height: 2rem;
            color: white;
            z-index: 1;
            display: block;
        }
        &:hover {
            background-color: rgba($color-bg, 0.2);
        }
    }
}
</style>

<i18n src="@dundle/locale/data/loyalty.json"></i18n>
